import { clearToken } from "actions/JhonDeere";
import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/User";
import { userManager, userManagerOffline } from "../../index";
import { onlineSelector } from "../../selectors/common";

const OFFLINE_TOKEN_RETRY_COUNT = 5;
export const getRefreshToken = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.GET_REFRESH_TOKEN_START
    });
    let retryCount = 0;
    let resOffline = null;

    try {
      resOffline = await userManagerOffline.getUser();
    } catch (e) {
      // eslint-disable-next-line
      console.log("userManagerOffline.getUser() Fail", e);
    }

    while (
      (!resOffline || (resOffline && !resOffline.refresh_token)) &&
      retryCount <= OFFLINE_TOKEN_RETRY_COUNT
    ) {
      await new Promise(resolve => setTimeout(resolve, 100));
      try {
        resOffline = await userManagerOffline.signinSilent();
      } catch (e) {
        //eslint-disable-next-line
        console.log("userManagerOffline.signinSilent() fail", e);
        retryCount++;
      }
    }

    if (resOffline) {
      dispatch({
        type: actionType.GET_REFRESH_TOKEN,
        payload: resOffline.refresh_token
      });
      return resOffline;
    } else {
      const message = "Unable to get offline token. Reason: signinSilent error";
      dispatch({
        type: actionType.GET_REFRESH_TOKEN_FAIL,
        payload: { error: message }
      });
      return { error: message };
    }
  } catch (error) {
    dispatch({
      type: actionType.GET_REFRESH_TOKEN_FAIL,
      payload: error.message
    });
    // eslint-disable-next-line
    console.log("Unable to get offline token. Reason " + error.message);
    return { error: error.message };
  }
};

export const signInSilent = () => async (dispatch, getState) => {
  const {
    oidc: { user }
  } = getState();
  try {
    dispatch({
      type: actionType.SIGN_IN_SILENT_START
    });
    let retryCount = 0;
    let res;

    if (user) {
      try {
        res = await userManager.getUser();
      } catch (e) {
        // eslint-disable-next-line
        console.log("signInSilent userManager.getUser() Fail", e);
      }
    }

    while (
      (!res || (res && res.expired)) &&
      retryCount <= OFFLINE_TOKEN_RETRY_COUNT
    ) {
      await new Promise(resolve => setTimeout(resolve, 100));
      try {
        res = await userManager.signinSilent();
      } catch (e) {
        //eslint-disable-next-line
        console.log("userManager.signinSilent() fail", e);
        retryCount++;
      }
    }
    if (res) {
      dispatch({
        type: actionType.SIGN_IN_SILENT,
        payload: res
      });
      return res;
    } else {
      // eslint-disable-next-line
      console.log("Cannot perform silent log in. Reason: signinSilent() Error");
      const online = onlineSelector(getState());
      const message = "signinSilent() Error";
      dispatch({
        type: actionType.SIGN_IN_SILENT_FAIL,
        payload: { error: message, online }
      });
      return { error: message };
    }
  } catch (error) {
    const online = onlineSelector(getState());
    dispatch({
      type: actionType.SIGN_IN_SILENT_FAIL,
      payload: { error: error.message, online }
    });
    // eslint-disable-next-line
    console.log("Cannot perform silent log in. Reason: " + error.message);
    return { error: error.message };
  }
};

export const signIn = () => async (dispatch, getState) => {
  const online = onlineSelector(getState());
  try {
    dispatch({
      type: actionType.SIGN_IN_START
    });
    await userManagerOffline.removeUser();
    if (online) {
      await userManager.signinRedirect();
    }
    dispatch({
      type: actionType.SIGN_IN
    });
  } catch (error) {
    dispatch({
      type: actionType.SIGN_IN_FAIL,
      payload: error.message
    });
    // eslint-disable-next-line
    console.log(
      "Unable to redirect to the Authorization Server. Reason " + error.message
    );
    return { error: error.message };
  }
};

export const signOut = () => async (dispatch, getState) => {
  localStorage.setItem("userCheck", JSON.stringify(false));
  const online = onlineSelector(getState());
  const { user } = getState();
  try {
    dispatch({
      type: actionType.SIGN_OUT_START
    });
    await userManager.removeUser();
    await userManagerOffline.removeUser();
    if (!JSON.parse(localStorage.getItem("userCheck")))
      await dispatch(clearToken());
    if (online) {
      const idToken = user.user.id_token;
      await userManager.signoutRedirect({
        id_token_hint: idToken
      });
    }

    dispatch({
      type: actionType.SIGN_OUT
    });
  } catch (error) {
    dispatch({
      type: actionType.SIGN_OUT_FAIL,
      payload: error.message
    });
    // eslint-disable-next-line
    console.log("Unable to finish sign out process. Reason " + error.message);
    return { error: error.message };
  }
};

export const getProfilePicture = () => async (dispatch, getState) => {
  const {
    user: { user }
  } = getState();
  if (!(user && user.profile && user.profile.picture)) {
    return;
  }
  const url = `/employees/pictures/presign_get_url/${user.profile.picture}`;
  return dispatch({
    type: actionType.GET_PROFILE_PICTURE_START,
    meta: {
      user,
      offline: {
        effect: {
          url,
          method: "GET"
        },
        commit: {
          type: actionType.GET_PROFILE_PICTURE,
          meta: {}
        },
        rollback: {
          type: actionType.GET_PROFILE_PICTURE_FAIL,
          meta: {}
        }
      }
    }
  });
};

export const updateProfileData = data => async dispatch => {
  await dispatch({
    type: actionType.UPDATE_PROFILE_DATA,
    payload: data
  });

  return dispatch(getProfilePicture());
};
export const checkCurrentUser = () => async () => {
  await localStorage.setItem("userCheck", JSON.stringify(true));
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `/employees/current`,
      shouldUseRetryConfig: false
    });
    return res.status;
  } catch (error) {
    return error.response?.status || null;
  }
};
