import moment from "moment";
import { actionType } from "constants/Chemicals";
import genericAttachmentUploader from "utils/genericAttachmentUploader";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import { getChemicalsList as setSprayDiaryChemicals } from "../SprayDiary/chemicals";
import uuid from "uuid/v4";

const url = "/chemicals";
const changeLogUrl = "/changeLog";
let db;
let uniqId;

let openRequest = indexedDB.open("changelog_db", 1);

openRequest.onupgradeneeded = function(e) {
  db = e.target.result;
  if (!db.objectStoreNames.contains("store")) {
    const storeOS = db.createObjectStore("store", { keyPath: "name" });
  }
};

openRequest.onsuccess = function(e) {
  db = e.target.result;
  addData();
};

const addData = item => {
  return new Promise(resolve => {
    let openRequest = indexedDB.open("changelog_db", 1);
    openRequest.onsuccess = () => {
      db = openRequest.result;
      if (db.objectStoreNames.contains("store")) {
        const tx = db.transaction(["store"], "readwrite");
        const store = tx.objectStore("store");
        item && store.put(item);
        resolve(item);
      }
    };

    openRequest.onerror = () => {
      const error = openRequest.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve("Unknown error");
      }
    };
  });
};

export const fetchChemicalsList = (forceFetch = false) => async (
  dispatch,
  getState
) => {
  const {
    user: { user },
    chemical
  } = getState();
  const clientId = localStorage.getItem("clientId");
  if (
    !chemical.list.content.length ||
    !clientId ||
    clientId !== user.profile.client_id ||
    forceFetch
  ) {
    try {
      dispatch({
        type: actionType.FETCH_CHEMICALS_LIST_START
      });

      const lastUpdateDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      const res = await axiosProxy({
        method: "GET",
        url,
        params: {
          unpaged: true
        }
      });

      await dispatch({
        type: actionType.FETCH_CHEMICALS_LIST,
        payload: {
          data: res.data,
          lastUpdateDate
        }
      });

      dispatch(setSprayDiaryChemicals(res.data.content));
      const item = {
        name: "chemical",
        ...chemical,
        list: {
          ...res.data,
          isFetching: false
        },
        lastUpdateDate
      };
      await addData(item);
      localStorage.setItem("clientId", user.profile.client_id);

      return res;
    } catch (error) {
      dispatch({
        type: actionType.FETCH_CHEMICALS_LIST_FAIL,
        payload: error
      });
    }
  }
};

export const fetchChemicalsLog = () => async (dispatch, getState) => {
  const {
    chemical,
    chemical: { list, lastUpdateDate }
  } = getState();
  try {
    const startSyncDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

    const params = new URLSearchParams({
      unpaged: true,
      entityType: "CHEMICAL",
      ...(lastUpdateDate && { updatedAfter: lastUpdateDate })
    });

    const {
      data: { content }
    } = await axiosProxy({
      method: "GET",
      url: changeLogUrl,
      params
    });
    if (content.length > 0) {
      const { updatableChemicalsIds, deletableChemicalsIds } = content.reduce(
        (prev, item) => ({
          updatableChemicalsIds:
            item.updateType !== "DELETED"
              ? [...prev.updatableChemicalsIds, item.entityId]
              : prev.updatableChemicalsIds,
          deletableChemicalsIds:
            item.updateType === "DELETED"
              ? [...prev.deletableChemicalsIds, item.entityId]
              : prev.deletableChemicalsIds
        }),
        {
          updatableChemicalsIds: [],
          deletableChemicalsIds: []
        }
      );
      const updatedChemicals = list.content.filter(
        chemical => !deletableChemicalsIds.includes(chemical.id)
      );

      if (updatableChemicalsIds.length) {
        dispatch({
          type: actionType.FETCH_CHEMICALS_LIST_START
        });

        const params = new URLSearchParams({
          unpaged: true,
          ids: updatableChemicalsIds
        });

        const {
          data: { content: fetchedChemicals }
        } = await axiosProxy({
          method: "GET",
          url,
          params
        });

        fetchedChemicals.forEach(chemical => {
          const itemIndex = updatedChemicals.findIndex(
            item => item.id === chemical.id
          );

          if (itemIndex > -1) {
            updatedChemicals[itemIndex] = chemical;
          } else {
            updatedChemicals.push(chemical);
          }
        });
      }

      await dispatch({
        type: actionType.FETCH_CHEMICALS_LIST,
        payload: {
          data: {
            ...list,
            content: updatedChemicals,
            numberOfElements: updatedChemicals.length,
            totalElements: updatedChemicals.length
          }
        }
      });

      dispatch(setSprayDiaryChemicals(updatedChemicals));

      const item = {
        name: "chemical",
        ...chemical,
        list: {
          ...list,
          content: updatedChemicals,
          numberOfElements: updatedChemicals.length,
          totalElements: updatedChemicals.length,
          isFetching: false
        },
        lastUpdateDate: startSyncDate
      };
      await addData(item);
    } else {
      const i = {
        name: "chemical",
        ...chemical,
        list: { ...list },
        lastUpdateDate: startSyncDate
      };
      await addData(i);
    }

    dispatch({
      type: actionType.CHANGE_LAST_UPDATE_DATE,
      payload: startSyncDate
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_CHEMICALS_LIST_FAIL,
      payload: error
    });
  }
};

export const fetchUnits = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_UNITS_START });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}/units`
    });
    dispatch({ type: actionType.SET_UNITS, payload: res.data });
  } catch (e) {
    dispatch({ type: actionType.SET_UNITS, payload: [] });
  }
};

export const fetchChemicals = ({
  archived,
  page,
  size,
  search,
  sort = ["id,asc"],
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_CHEMICALS_START });

    const paramsObject = omitBy(
      {
        archived,
        page,
        size,
        search,
        sort,
        unpaged
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => params.append(filter, value));
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_CHEMICALS,
        payload: res.data
      });
    }

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_CHEMICALS_ERROR,
      payload: error
    });
  }
};

export const fetchChemicalById = id => async dispatch => {
  dispatch({
    type: actionType.FETCH_CHEMICALS_START
  });

  const { data } = await axiosProxy({
    method: "GET",
    url: `${url}/${id}`
  });

  await dispatch({
    type: actionType.FETCH_CHEMICALS,
    payload: { content: [data] }
  });
};

export const fetchFilterValues = () => async dispatch => {
  dispatch({
    type: actionType.FETCH_CHEMICALS_FILTERS_START
  });

  try {
    const typesResponse = await axiosProxy({ url: `${url}/types` });

    dispatch({
      type: actionType.FETCH_CHEMICALS_FILTERS_SUCCESS,
      payload: {
        chemicalTypeOptions: typesResponse.data.map(chemical => ({
          label: chemical.type,
          key: chemical.id,
          value: chemical.id
        }))
      }
    });
  } catch (e) {
    dispatch({
      type: actionType.FETCH_CHEMICALS_FILTERS_ERROR,
      payload: e.toString()
    });
  }
};

export const updateChemical = (id, data) => async dispatch => {
  dispatch({
    type: actionType.UPDATE_CHEMICAL_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/${id}`,
      data
    });

    await dispatch({
      type: actionType.UPDATE_CHEMICAL,
      payload: res.data
    });
    await dispatch(fetchChemicalsLog());
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.UPDATE_CHEMICAL_FAIL,
      payload: error
    });
  }
};

export const createChemical = data => async dispatch => {
  dispatch({
    type: actionType.ADD_CHEMICAL_START
  });
  try {
    const res = await axiosProxy({
      method: "POST",
      url,
      data
    });

    await dispatch({
      type: actionType.ADD_CHEMICAL,
      payload: res.data
    });
    await dispatch(fetchChemicalsLog());
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_CHEMICAL_FAIL,
      payload: error
    });
  }
};

export const changeChemicalsPage = page => dispatch => {
  dispatch({
    type: actionType.CHANGE_CHEMICALS_PAGE,
    payload: page
  });
};

export const changePageSize = pageSize => dispatch => {
  dispatch({
    type: actionType.CHANGE_CHEMICALS_PAGE_SIZE,
    payload: pageSize
  });
};
export const fetchActiveIngredients = ({ search } = {}) => async dispatch => {
  dispatch({
    type: actionType.FETCH_ACTIVE_INGREDIENTS_START
  });
  try {
    const params = new URLSearchParams();

    if (search) {
      params.append("search", search);
    }

    const res = await axiosProxy({
      url: `${url}/activeIngredients`,
      params
    });

    await dispatch({
      type: actionType.FETCH_ACTIVE_INGREDIENTS_SUCCESS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.FETCH_ACTIVE_INGREDIENTS_ERROR,
      payload: error
    });
  }
};

export const changeArchivedStatus = (tasksIds, archive) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_CHEMICALS_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=${archive}`
    });

    dispatch({
      type: actionType.ARCHIVE_CHEMICALS,
      payload: tasksIds
    });
    dispatch(fetchChemicalsLog());
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_CHEMICALS_FAIL,
      payload: error
    });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) =>
  genericAttachmentUploader({
    url: `${url}/attachments/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_CHEMICAL_ATTACHMENT_START,
    successAction: actionType.UPLOAD_CHEMICAL_ATTACHMENT,
    errorAction: actionType.UPLOAD_CHEMICAL_ATTACHMENT_ERROR,
    callBack,
    idReplace,
    data,
    parentId
  });

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CHEMICALS_CLEAR_ERROR
  });
};

export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_ITEM_ID, payload: id });
};

export const searchProductsByName = async name => {
  const res = await axiosProxy({
    method: "GET",
    params: new URLSearchParams({ search: name }),
    url
  });

  return res.data;
};
